import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';
import Link from '@mui/material/Link';
import Logo from 'images/northwoods-logo-main.png';

interface Props {
  pages: {
    company: Array<PageItem>;
    blog: Array<PageItem>;
  };
}

const SidebarNav = ({ pages }: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    company: companyPages,
    blog: blogPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Northwoods REI"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={Logo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <Link
            underline="none"
            href="/"
            color="text.primary"
            key="home"
          >
            Home
          </Link>
        </Box>
        <Box>
          <Link
            underline="none"
            href="#services"
            color="text.primary"
            key="services"
          >
            Services
          </Link>
        </Box>
        <Box>
          <Link
            underline="none"
            href="#about"
            color="text.primary"
            key="about"
          >
            About
          </Link>
        </Box>
        <Box>
          <Link
            underline="none"
            href="#analytics"
            color="text.primary"
            key="whyUs"
          >
            Why us
          </Link>
        </Box>
        <Box>
          <Link
            underline="none"
            href="/blog"
            color="text.primary"
            key="blog"
          >
            Blog
          </Link>
        </Box>
        {/* <Box marginTop={2}>
          <Button
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="/docs/introduction"
          >
            Documentation
          </Button>
        </Box>
        <Box marginTop={1}>
          <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="https://MUI.com/store/items/the-front-landing-page/"
          >
            Purchase now
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default SidebarNav;
